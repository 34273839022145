export default {
  async findOrder(orderId: number): Promise<SubscriptionBoxOrder> {
    return await apiGet(`subscription-box-order/${orderId}`)
  },
  async findEdition(orderId: number): Promise<SubscriptionBoxEdition> {
    return await apiGet(`subscription-box-order/${orderId}/edition`)
  },
  async findFeedback(orderId: number): Promise<SubscriptionBoxFeedback> {
    return await apiGet(`subscription-box-order/${orderId}/feedback`)
  },
  async findProducts(orderId: number): Promise<SubscriptionBoxProduct[]> {
    return await apiGet(`subscription-box-order/${orderId}/products`)
  },
  async findAdditionalProducts(orderId: number): Promise<SubscriptionBoxAdditionalProduct[]> {
    return await apiGet(`subscription-box-order/${orderId}/additional-products`)
  },
  async findTracking(orderId: number): Promise<SubscriptionBoxOrderTracking> {
    return await apiGet(`subscription-box-order/${orderId}/tracking`)
  },
  async registerFeedback(orderId: number, request: RegisterSubscriptionBoxFeedbackRequest): Promise<void> {
    return await apiPost(`subscription-box-order/${orderId}/feedback`, { body: request })
  }
}

export function subscriptionBoxOrderTitle(order: SubscriptionBoxOrder): string {
  const year = order.edition.slice(0, 4)
  const month = order.edition.slice(4, 6)
  var monthName = ''

  switch (month) {
    case '01':
      monthName = 'Janeiro'
      break
    case '02':
      monthName = 'Fevereiro'
      break
    case '03':
      monthName = 'Março'
      break
    case '04':
      monthName = 'Abril'
      break
    case '05':
      monthName = 'Maio'
      break
    case '06':
      monthName = 'Junho'
      break
    case '07':
      monthName = 'Julho'
      break
    case '08':
      monthName = 'Agosto'
      break
    case '09':
      monthName = 'Setembro'
      break
    case '10':
      monthName = 'Outubro'
      break
    case '11':
      monthName = 'Novembro'
      break
    case '12':
      monthName = 'Dezembro'
      break
  }

  return `${monthName} ${year}`
}

export interface SubscriptionBoxOrder {
  tenant: string,
  id: number,
  customerId: number,
  edition: string,
  status: string,
  nfeAccessKey?: string,
  trackingCode?: string,
  trackingUrl?: string,
  paidAt?: string,
  inProductionAt?: string,
  readyToPostAt?: string,
  inTransitAt?: string,
  deliveredAt?: string,
  createdAt: string
}

export interface SubscriptionBoxEdition {
  tenant: string,
  id: string,
  magazineDescription?: string
}

export interface SubscriptionBoxOrderTracking {
  shippingCompany: string,
  trackingCode: string,
  status: string,
  events: SubscriptionBoxOrderTrackingEvent[]
}

export interface SubscriptionBoxOrderTrackingEvent {
  instant: string,
  description: string,
  detail?: string,
  origin?: SubscriptionBoxOrderTrackingEventLocation,
  destination?: SubscriptionBoxOrderTrackingEventLocation,
  address?: SubscriptionBoxOrderTrackingAddress
}

export interface SubscriptionBoxOrderTrackingEventLocation {
  location: string,
  city?: string,
  state: string
}

export interface SubscriptionBoxOrderTrackingAddress {
  cep: string,
  street?: string,
  number?: string,
  complement?: string,
  district?: string,
  city: string,
  state: string
}

export interface SubscriptionBoxFeedback {
  tenant: string,
  customerId: number,
  orderId: number,
  orderScore: number,
  orderFeedback?: string,
  productFeedbacks: SubscriptionBoxProductFeedback[],
  additionalAnswers: SubscriptionBoxFeedbackAdditionalAnswer[]
}

export interface SubscriptionBoxProductFeedback {
  productId: number
  score: number
  feedback?: string
}

export interface SubscriptionBoxFeedbackAdditionalAnswer {
  questionId: number
  choices: string[]
}

export interface RegisterSubscriptionBoxFeedbackRequest {
  orderScore: number
  productFeedbacks: SubscriptionBoxProductFeedback[]
  additionalAnswers: SubscriptionBoxFeedbackAdditionalAnswer[],
  orderFeedback?: string
}

export interface SubscriptionBoxProduct {
  id: number,
  name: string,
  description?: string,
  brandId: number,
  brandName: string,
  traits: string[],
  matchingTraits: string[]
}

export interface SubscriptionBoxAdditionalProduct {
  id: number,
  name: string,
  description?: string,
  brandId: number,
  brandName: string,
}
